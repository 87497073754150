import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Personalisierbar auf Ihren Welpen und Ihre Bedürfnisse"
const text =
  "Die Rasse und das Alter Ihres Welpen sowie Ihre Bedürfnisse und Vorkenntnisse bestimmen die Informationen, die für Sie gerade wichtig sind. Alle Tipps und Checklisten können Sie auf Ihren Welpen und Ihr Situation anpassen sowie Aufgaben reduzieren oder ergänzen."

const Personalisierbar = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader
        page={"personalisierbar"}
        headline={headline}
        text={text}
      />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Personalisierbar
